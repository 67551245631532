import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import SEO from 'components/SEO';
import Section from 'components/Section';
import Button from 'components/Button';
import Link from 'components/Link';

export default function NotFoundPage() {
  const { t } = useTranslation();

  return (
    <>
      <SEO
        title={t('common:404.title')}
        description={t('common:404.desc')}
        keywords={t('common:404.keywords')}
      />

      <Section>
        <div className="text-center">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl">
            {t('common:404.title')}
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            {t('common:404.desc')}
          </p>
        </div>

        <div className="sm:flex sm:justify-center mt-12 sm:mt-24 space-y-4 sm:space-y-0 sm:space-x-4">
          <Button as={Link} href="/downloader" color="secondary" size="xl">
            {t('common:dl_online')}
          </Button>
          <Button as={Link} href="/converter" size="xl">
            {t('common:conv_online')}
          </Button>
        </div>
      </Section>
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "videoproc"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
